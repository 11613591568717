<template>
  <div>
    <b-row class="d-flex justify-content-between header mb-1">
      <b-col>
        <h1 class="serverName text-primary ml-2">
          <strong>
            {{ `${eventInfo.event_identifier}` }}
          </strong>
        </h1>
      </b-col>
    </b-row>
    <b-row class="ml-2 mb-2 d-flex justify-content-between">
      <div>
        {{ $t("common.terms.created_at") }}:
        <strong class="text-primary pl-50"> {{ eventInfo.created_at }}</strong>
      </div>
      <div>
        {{ $t("common.terms.updated_at") }}:
        <strong class="text-primary pl-50 mr-3">
          {{ eventInfo.updated_at }}</strong
        >
      </div>
    </b-row>

    <b-tabs horizontal nav-wrapper-class="nav-bottom" class="ml-2">
      <b-tab :title="$t('event.tabs.info.title')" active>
        <b-row>
          <b-col md="6" class="mt-4">
            <b-row>
              <b-col class="mb-1" cols="12">
                <b-form-group
                  :label="$t('event.tabs.info.label.identifier')"
                  label-for="event-identifier"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="
                      eventInfo.event_identifier ||
                      eventInfo.event_identifier == ''
                    "
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BoxIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="event-identifier"
                      :value="`${eventInfo.event_identifier}`"
                      readonly
                    />
                    <b-input-group-append is-text>
                      <div
                        v-if="!userCantEdit"
                        class="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editEvent('Event_Identifier')"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="`Edit2Icon`"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12">
                <b-form-group
                  :label="$t('event.tabs.info.label.description')"
                  label-for="event-description"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="eventInfo.description || eventInfo.description == ''"
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="event-description"
                      readonly
                      :value="`${eventInfo.description}`"
                    />
                    <b-input-group-append is-text>
                      <div
                        v-if="!userCantEdit"
                        class="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editEvent('Description')"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="`Edit2Icon`"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12">
                <b-form-group
                  :label="$t('event.tabs.info.label.retry')"
                  label-for="event-description"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="retryInfo.label"
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="event-description"
                      readonly
                      :value="$t(retryInfo.label)"
                    />
                    <b-input-group-append is-text>
                      <div
                        v-if="!userCantEdit"
                        class="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editEvent('Enum_Retry')"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="`Edit2Icon`"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                v-if="retryInfo.label && eventInfo.enum_retry_id != 1"
                class="mb-1"
                cols="12"
              >
                <b-form-group
                  :label="$t(retryInfo.label)"
                  label-for="event-description"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="typeof eventInfo.max_retry == 'number'"
                    class="
                      input-group-merge
                      d-flex
                      justify-content-between
                      mt-50
                    "
                  >
                    <p class="text-light"><strong>0</strong></p>
                    <vue-slider
                      v-model="sliderValue"
                      :width="460"
                      direction="ltr"
                      :max="retryInfo.max_attempts"
                      :process="false"
                      :tooltip="'always'"
                      :tooltip-placement="'bottom'"
                      :class="
                        sliderValue != eventInfo.max_retry
                          ? 'vue-slider-warning'
                          : 'vue-slider-primary'
                      "
                      :dot-style="{ backgroundColor: '#12e066' }"
                    />
                    <p class="text-light">
                      <strong>{{ retryInfo.max_attempts }}</strong>
                    </p>
                  </b-input-group>
                </b-form-group>
                <b-col
                  class="d-flex justify-content-end"
                  @click="editMaxRetry('Max_Retry')"
                >s
                  <b-button
                    v-if="sliderValue != eventInfo.max_retry"
                    variant="primary"
                  >
                    {{ $t("common.terms.save") }}
                  </b-button>
                </b-col>
              </b-col>

              <!-- <b-col class="mb-1" cols="12">
                <b-form-group :label="$t('info.enum_retry_id')" label-for="gx-event-id" label-cols-md="4">
                  <b-input-group v-if="eventInfo.enum_retry_id" class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="KeyIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="gx-event-id"
                      readonly 
                      :type="eventTokenInputType"
                      :value="`${eventInfo.enum_retry_id}`"
                    />
                    <b-input-group-append is-text>
                      <div class="btn btn-secondary text-white py-25 px-75" @click="toggleEventTokenVisibility()">
                        <feather-icon
                            class="cursor-pointer"
                            :icon="eventTokenIcon"
                          />
                      </div>
                      <div class="ml-1 btn btn-warning text-white py-25 px-75">
                        <feather-icon
                            class="cursor-pointer"
                            :icon="`Edit2Icon`"
                            @click="toggleEventTokenVisibility()"
                          />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col> -->
            </b-row>
          </b-col>
          <b-col md="6" cols="12" v-if="eventInfo.event_identifier">
            <scoped-vars
              :identifier="`${eventInfo.event_identifier}.$NONE`"
              scope="@MIDDLEWARES"
            ></scoped-vars>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$t('event.tabs.permission.title')">
        <b-row class="d-flex justify-content-between header" v-if="!userCantEdit">
          <b-col> </b-col>
          <b-button
            class="mr-2"
            variant="relief-success"
            @click="$refs['add-permission-modal'].show()"
          >
            {{ $t("event.tabs.permission.add") }}
          </b-button>
        </b-row>

        <hr class="my-2" />
        <BsTable
          :key="'permissions-bs-table-'+tableKey"
          tableName="eventServices"
          :fields="tableFields.eventServices"
          :actions="tableActions.eventServices"
          :has_paginator="false"
          :show_filter="true"
          :checkToggleItems="[
            { name: 'can_receive', event: 'changeCanReceive' },
            { name: 'can_send', event: 'changeCanSend' },
          ]"
        />
      </b-tab>
      <b-tab :title="$t('event.tabs.object.title')">
        <b-row class="d-flex justify-content-between header mb-2">
          <b-col>
            <!-- <b-button
              class="mr-2"
              variant="info"
              @click="openDetectObjectFieldsFromJSON"
            >
              <feather-icon icon="CheckIcon" />
              {{ $t("event.tabs.object.detectJSON") }}
            </b-button> -->
          </b-col>
          <b-button class="mr-3" variant="danger" @click="deleteFullJSONObject" v-if="!userCantEdit">
            <feather-icon icon="TrashIcon" />
            {{ $t("event.tabs.object.deleteJSON") }}
          </b-button>
        </b-row>
        <b-card>

            <json-editor
              id="detect-json"
              key="detect-json"
              v-model="detectJsonField"
              :modes="['code', 'tree', 'preview']"
              height="450px"
            />
            <div align="right">
              <b-button variant="success" class="mt-50" @click="detectObjectFieldsFromJSON()">Save</b-button>
            </div>
        </b-card>
      </b-tab>
    </b-tabs>
    <base-modal />
    
    <b-modal
      ref="add-permission-modal"
      title="Creating Permission"
      centered
      @ok="handleAddPermission"
      @hide="(e)=>{ if (modalInfo.loading) e.preventDefault()}"
      no-close-on-backdrop
    >
      <template #modal-ok>
        <span v-if="!modalInfo.loading">
          Confirm
        </span>
        <b-spinner v-else small/>
      </template>

      <label>Service</label>
      
      <v-select
        :options="getTransmissionServices"
        :clearable="false"
        v-model="modalInfo.selecetedService"
      >
        <template #selected-option="data">
          {{data.identifier}}
        </template>
        <template #option="data">
          {{data.identifier}}
        </template>

      </v-select>

      

    </b-modal>

    <b-modal
      id="detect-json"
      size="lg"
      centered
      :title="$t('event.tabs.object.modal.title')"
      lazy
      :okTitle="$t('common.terms.save')"
      :cancelTitle="$t('common.terms.cancel')"
      @ok.prevent="detectObjectFieldsFromJSON"
    >
      <b-form class="w-100">
        <b-row class="mt-1">
          <b-form-group
            :label="$t('event.modal.input.label.json_object')"
            label-for="detect-json"
            class="w-100 mx-1"
          >
            <json-editor
              id="detect-json"
              key="detect-json"
              v-model="detectJsonField"
              :modes="['code', 'tree', 'preview']"
              height="450px"
            />
          </b-form-group>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import BsTable from "@/layouts/components/BsTable.vue";
import EventBus from "@/custom/eventBus";
import { makeToast } from "@/layouts/components/Popups.js";
import VueSlider from "vue-slider-component";
import VJsoneditor from "v-jsoneditor";
import VueJsonPretty from "vue-json-pretty";
import VueJsonEditor from "vue-json-editor";
import "vue-json-pretty/lib/styles.css";
import ScopedVars from "@/layouts/components/Transmission/ScopedVars.vue";
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import VSelect from 'vue-select'
import { mapGetters } from "vuex";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
  BCard,
  BSpinner
} from "bootstrap-vue";
import { successToast, errorToast } from "@/custom/class/FunctionClasses/CommonToasts";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BaseModal,
    BCard,
    BSkeleton,
    BsTable,
    VueSlider,
    VJsoneditor,
    VueJsonPretty,
    VueJsonEditor,
    ScopedVars,
    JsonEditor,
    BSpinner,
    VSelect,
  },
  data() {
    return {
      eventInfo: [],
      tableFields: {
        eventServices: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: true,
          },
          {
            key: "identifier",
            label: this.$t("common.table.fields.identifier"),
            sortable: true,
          },
          {
            key: "can_send",
            label: this.$t("events.table.fields.send"),
            sortable: true,
          },
          {
            key: "can_receive",
            label: this.$t("events.table.fields.receive"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
      },
      tableActions: {
        eventServices: custom.tableActions.eventServices,
      },
      eventTokenIcon: "EyeIcon",
      eventTokenInputType: "password",
      eventTokenVisible: false,
      retryInfo: [],
      sliderValue: null,
      objectField: null,
      detectJsonField: null,
      modalInfo: {
        selecetedService: undefined,
        loading: false        
      },
      tableKey: 0
    };
  },
  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },
  mounted() {
    this.init();

    EventBus.$on("changeCanReceive", (payload) => {
      this.$store
        .dispatch("changeCanReceive", {
          item: payload.item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          EventBus.$emit(payload.done_event, payload.done);

          makeToast(custom.successMessages.canReceive);
        })
        .catch((e) => {
          makeToast(custom.errorMessages.canReceive);
        });
    });

    EventBus.$on("changeCanSend", (payload) => {
      this.$store
        .dispatch("changeCanSend", {
          item: payload.item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          EventBus.$emit(payload.done_event, payload.done);

          makeToast(custom.successMessages.canSend);
        })
        .catch((e) => {
          makeToast(custom.errorMessages.canSend);
        });
    });

    this.putObjectInEditor()
  },
  computed: {
    ...mapGetters(['getTransmissionServices']),
    transmissionID() {
      return this.$route.params.transmissionID;
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  beforeDestroy() {
    EventBus.$off("changeCanSend");
    EventBus.$off("changeCanReceive");
  },
  methods: {
    console() {
      console.log(this.eventInfo);
      console.log(this.retryInfo);
    },
    init() {
      this.fetchEventById();
      this.fetchServices();
      this.buildActions();
    },
    buildActions() {
      
      this.tableActions.eventServices["third-slot"] = {
        type: "exec",
        title: this.$t("middleware.tooltip.copy_payload_to_clipboard"),
        action: this.getPayloadJSON,
        icon: "FileTextIcon",
        color: "text-warning",
        variantColor: "outline-warning",
      };
    },
    fetchServices(){
      this.$store.dispatch('getServicesByTransmissionGroup', {transmissionID: this.$route.params.transmissionID})
        .then((resp)=>{
          
        })
        .catch((err)=>{
          console.error(err)
        })
    },
    async fetchEventById() {
      this.$store
        .dispatch("getEventbyId", this.$route.params)
        .then(async (response) => {
          this.$store.commit("SET_CURRENT_EVENT", response);
          this.getEventObjectField();
          this.eventInfo = response;
          this.sliderValue = response.max_retry;
          this.getEnumRetryInfo(response.enum_retry_id);
        });
    },
    getEnumRetryInfo(enum_retry_id) {
      this.$store.dispatch("getEnumRetryInfo", enum_retry_id).then((resp) => {
        this.retryInfo = resp;
      });
    },
    toggleEventTokenVisibility() {
      this.eventTokenVisible = !this.eventTokenVisible;
      if (this.eventTokenVisible) {
        this.eventTokenIcon = "EyeOffIcon";
        this.eventTokenInputType = "text";
      } else {
        this.eventTokenIcon = "EyeIcon";
        this.eventTokenInputType = "password";
      }
    },
    handleAddPermission(e) {
      e.preventDefault()
      if (!this.modalInfo.selecetedService){
        return
      }
      this.modalInfo.loading = true
      const payload = {
        transmissionID: this.$route.params.transmissionID,
        data:{
          event: this.eventInfo.id,
          service: this.modalInfo.selecetedService.id
        }
      }
      this.$store.dispatch('addEventPermission', payload).then((resp)=>{

        successToast({text: 'Permission added'})
        this.modalInfo.loading = false
        
        this.$nextTick(()=>{
          this.$refs['add-permission-modal'].hide()
        })
        this.tableKey++
      })
      .catch((err)=>{
        console.error(err)
        errorToast({text:"Permission could not be created"})
      })
      .finally(()=>{
        this.modalInfo.loading = false
      })

    },
    editEvent(action) {
      this.$store.dispatch("setEventAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editEvent${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {});
      });
    },
    editMaxRetry(action) {
      this.$store.dispatch("setEventAction", action).then(() => {
        this.$store
          .dispatch("editEvent", { newEventMax_Retry: this.sliderValue })
          .then((response) => {
            console.log(response);
            this.eventInfo.max_retry = parseInt(response.max_retry);
          })
          .catch(() => {});
      });
    },
    onEditDone: function (previous, current, item, parentPath) {
      return new Promise((resolve, reject) => {
        if (previous === current) {
          resolve();
        } else {
          this.$store
            .dispatch("updateEventObjectField", {
              item,
              parentPath,
              ...this.$route.params,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    actionFunction: (item) => {
      console.log("CLICOU NO ZEZIN.\nZEZIN = ", item);
    },
    onAdd: function (item, parentPath) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("addEventObjectField", {
            item,
            parentPath,
            ...this.$route.params,
          })
          .then((response) => {
            console.log(response);
            resolve(item);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onDelete: function (item, parent) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("deleteEventObjectField", {
            item: item,
            ...this.$route.params,
          })
          .then((response) => {
            resolve(item);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEventObjectField() {
      this.$store
        .dispatch("getEventObjectField", this.$route.params)
        .then((response) => {
          this.objectField = response;
          this.putObjectInEditor()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDetectObjectFieldsFromJSON() {
      this.$bvModal.show("detect-json");
    },
    detectObjectFieldsFromJSON() {
      this.$store
        .dispatch("detectObjectFieldsFromJSON", {
          jsonObject: this.detectJsonField,
          transmissionID: this.$route.params.transmissionID,
        })
        .then(() => {
          successToast({text:"Object updated successfully"})
          this.getEventObjectField();
          this.$bvModal.hide("detect-json");
        });
    },
    deleteFullJSONObject() {
      this.$bvModal
        .msgBoxConfirm(this.$t("confirm.delete.allNodes"), {
          title: "service.token.title",
          okTitle: "terms.confirmButton",
          okVariant: "primary",
          cancelTitle: "terms.cancelButton",
          cancelVariant: "outline-danger",
          size: "md",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("deleteAllObjectFields", { ...this.$route.params })
              .then((response) => {
                console.log(response);
                makeToast(custom.successMessages.deleteFullJSONObject);
              })
              .catch(() => {
                makeToast(custom.errorMessages.deleteFullJSONObject);
              });
          }
        });
    },
    getPayloadJSON(item) {
      const j = {
        event: this.eventInfo.event_identifier,
        token: item.service.token,
        sent_from: item.service.identifier,
        data: this.objectField,
      };

      navigator.clipboard
        .writeText(JSON.stringify(j))
        .then(() => {
          makeToast({
            title: this.$t("service_event.toast.copy_payload.success.title"),
            text: this.$t("service_event.toast.copy_payload.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch(() => {
          makeToast({
            title: this.$t("service_event.toast.copy_payload.error.title"),
            text: this.$t("service_event.toast.copy_payload.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    putObjectInEditor(){
      let r = structuredClone(this.objectField);
      this.detectJsonField = r;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
